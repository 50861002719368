import classnames from 'classnames';

import { useTranslation } from 'hooks';
import { Button, Popup } from 'components/assessment';
import { useAssessmentStateActions } from 'AppFlow/Summary/Assessment/state';

import s from './SkillStrengthCard.module.scss';
import useSkillStrengthCardState from './state/useStrengthCardState';
import { useSkillStrengthCardStateActions } from './state/selectors';
import { TranslationPage } from '../../../types/enums/TranslationPage';

type Props = {
  title: string;
  subtitle?: string;
  description: string;
  type: 'emotionalIntelligence'
  | 'cognitive'
  | 'personality'
  | 'behavior';
  popupContent?: React.ReactElement[] | React.ReactElement | undefined;
  interactionType: 'mainFeedbackInteractions' | 'entrepreneurshipInteractions';
};

const SkillStrengthCard: React.FC<Props> = ({
  title,
  subtitle,
  description,
  type,
  popupContent,
  interactionType,
}) => {
  const { isSkillsStrengthCardOpen, skillsStrengthCardOpenName } = useSkillStrengthCardState();
  const { toggleSkillStrengthCard } = useSkillStrengthCardStateActions();
  const { incrementInteraction } = useAssessmentStateActions();
  const lang = useTranslation(TranslationPage.SKILL_STRENGTH_CARD);
  const onLearnMoreClick = () => {
    if (subtitle) {
      incrementInteraction(interactionType, subtitle);
    }
    toggleSkillStrengthCard(true, type);
  };

  return (
    <div
      className={classnames(s.card, {
        [s.emotionalIntelligence]: type === 'emotionalIntelligence',
        [s.cognitive]: type === 'cognitive',
        [s.personality]: type === 'personality',
        [s.behavior]: type === 'behavior',
      })}
    >
      <span className={s.icon} />
      <h4 className={s.title}>{title}</h4>
      {/* { subtitle && type !== 'personality' && <span className={s.subtitle}>{subtitle}</span>} */}
      { description && <p className={s.description}>{description}</p>}

      {
        popupContent && (
          <>
            <Button onClick={onLearnMoreClick}>{lang.learnMore}</Button>
            <Popup
              isOpen={isSkillsStrengthCardOpen && skillsStrengthCardOpenName === type}
              onClose={() => toggleSkillStrengthCard(false)}
            >
              {popupContent}
            </Popup>
          </>
        )
      }
    </div>
  );
};

export default SkillStrengthCard;
