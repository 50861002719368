function luhnAlgorithm(rsa: string): boolean {
  let sum = 0;
  for (let i = 0; i < rsa.length - 1; i++) {
    let digit = parseInt(rsa[i], 10);
    console.log("double", i % 2 === rsa.length % 2, i % 2, rsa.length % 2);
    if (i % 2 === rsa.length % 2) {
      digit *= 2;
      if (digit > 9) digit -= 9;
    }
    console.log('add', sum, digit);
    sum += digit;
  }
  const calculatedCheckDigit = (10 - (sum % 10)) % 10;
  const actualCheckDigit = parseInt(rsa[rsa.length - 1], 10);
  return calculatedCheckDigit === actualCheckDigit;
}

export { luhnAlgorithm };
