import { useCallback, useState } from 'react';
import { Tab } from 'AppFlow/Summary/Assessment/enums';
import classNames from 'classnames';
import { useViewportSize } from 'appState';
import s from './TabToggle.module.scss';

const SELECTION_DELAY = 300;

type Props<T> = {
  items: {
    id: T;
    name: string;
    primary?: boolean;
    visible?: boolean;
  }[];
  onSelected: (item: T) => void;
  activeTab: Tab;
};

function TabToggle<T extends string = string>({
  items,
  onSelected,
  activeTab,
}: Props<T>) {
  const v = useViewportSize();
  const [isOpen, setOpen] = useState(false);
  const toggleOpen = () => setOpen(o => !o);

  const onClick = useCallback((itemId: T) => {
    setOpen(false);
    const timeoutRef = setTimeout(
      () => onSelected(itemId),
      SELECTION_DELAY,
    );
    return () => clearTimeout(timeoutRef);
  }, [onSelected, setOpen]);

  return (
    <div className={s.wrapper}>
      {items
        .filter((item) => item.primary)
        .map(({ name, id }) => (
          <span
            key={id.toString()}
            onClick={() => onClick(id)}
            className={classNames(s.tab, {
              [s.activeTab]: activeTab === name.toLocaleLowerCase(),
            })}
          >
            {name}
          </span>
        ))}
      {/* <button
        className={classNames(s.toggler, isOpen && s.open)}
        type="button"
        onClick={toggleOpen}
      >
        menu
      </button> */}
      <ul
        className={classNames(s.menu, isOpen && s.open)}
        style={{ height: `${v.height}px` }}
      >
        {items
          .filter((item) => item.visible)
          .map(({ id, name, primary }) => (
            <li
              key={id.toString()}
              className={classNames(s.item, primary ? s.primary : s.secondary)}
            >
              <span className={s.label} onClick={() => onClick(id)}>
                {name}
              </span>
            </li>
          ))}
      </ul>
    </div>
  );
}

export default TabToggle;
